<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <ChooseFormPopup v-if="draft.showForm" @onFormSelect="draft.setForm"  @onClose="draft.showForm=false"></ChooseFormPopup>
                <LoadPopup v-if="draft.showLoadPopup" @close="draft.showLoadPopup=false" @selected="draft.setInfo"></LoadPopup>
                <ApprovalLinePopup v-if="draft.showApprovalLine" @close="draft.showApprovalLine=false" @selected="draft.setStaff"></ApprovalLinePopup>
                <ApprovalLineLoadPopup v-if="draft.showApprovalLineLoad" @close="draft.showApprovalLineLoad=false"></ApprovalLineLoadPopup>
                <CarrotTitle title="기안작성">
                    <div class="btn-ibbox title-btnbox float-right">
                        <button class="btn-default" @click="draft.showForm=true">양식선택</button>
                        <button class="btn-default ml-10" @click="draft.showLoadPopup=true">불러오기</button>
                        <button class="btn-default ml-10" @click="draft.showApprovalLine=true">결재선</button>
                    </div>
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <div class="dp-table w-100per">
                                <div class="float-left w-50per pr-25"> 
                                    <table class="table-row">
                                        <tbody>
                                            <tr>
                                                <th width="150">기안자</th>
                                                <td>{{draft.docinfo.ename}} ({{draft.docinfo.kname}})</td>
                                            </tr>
                                            <tr>
                                                <th width="150">소속</th>
                                                <td>{{draft.docinfo.sector_name}}/{{draft.docinfo.deptname}}</td>
                                            </tr>
                                            <tr>
                                                <th width="150">작성일</th>
                                                <td>{{draft.docinfo.createdate}}</td>
                                            </tr>
                                            <tr>
                                                <th width="150">문서번호</th>
                                                <td>{{draft.docinfo.no}}</td>
                                            </tr>
                                            <tr>
                                                <th width="150">보안</th>
                                                <td>
                                                    <label><input type="checkbox" id="Private" name="Private" v-model="draft.is_private"  value="Y"><span class="ml-5"> Private</span></label>
                                                    <span class="ml-15 color-1">* 급여성, 인사관련사항 결재 시 체크해주세요.</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="float-right w-50per pl-25">
                                    <table class="table-col">
                                        <colgroup>
                                            <col :span="draft.line_staff.length"><!--결재지정한 수만큼(thead th수)-->
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>담당</th>
                                                <th v-for="s in draft.line_staff" :key="s.idx">{{s.position}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{draft.docinfo.ename}}</td>
                                                <td v-for="s in draft.line_staff" :key="s.idx">{{s.ename}}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table-row mt-45">
                                        <colgroup>
                                            <col width="100">
                                            <col width="200">
                                            <col width="*">
                                            <col width="100">
                                        </colgroup>
                                        <tbody>
                                            <tr v-for="(s, idx) in draft.cc_staff" :key="s.idx">
                                                <th v-if="idx==0" :rowspan="draft.cc_staff.length">참조</th>
                                                <td>{{s.deptname}}</td>
                                                <td>{{s.ename}}({{s.kname}})</td>
                                                <td>미확인</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <table class="table-row mt-45">
                                <tbody>
                                    <tr>
                                        <th width="150">제목</th>
                                        <td>
                                            <input type="text" v-model="draft.title" maxlength="50" class="w-100per">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <AmountPaid v-if="draft.form_type=='AmountPaid'" ref="amountPaid" v-model="draft.json_data"></AmountPaid>
                            <ExtraPay v-if="draft.form_type=='ExtraPay'" ref="extraPay" v-model="draft.json_data"></ExtraPay>
                            <OvertimePay v-if="draft.form_type=='OvertimePay'" ref="overtimePay" v-model="draft.json_data"></OvertimePay>
                            <AnnualLeave v-if="draft.form_type=='AnnualLeave'" ref="annualLeave" v-model="draft.json_data"></AnnualLeave>
                            <AnnualLeaveExtra v-if="draft.form_type=='AnnualLeaveExtra'" ref="annualLeaveExtra" v-model="draft.json_data"></AnnualLeaveExtra>
                            <WorkAtHome v-if="draft.form_type=='WorkAtHome'" ref="workAtHome" v-model="draft.json_data"></WorkAtHome>
                            <ExternalBook v-if="draft.form_type=='ExternalBook'" ref="externalBook" v-model="draft.json_data"></ExternalBook>



                            <AnnualLeaveNew v-if="draft.form_type=='AnnualLeaveNew'" ref="annualLeaveNew" v-model="draft.json_data"></AnnualLeaveNew>
                            <AnnualLeaveOuting v-if="draft.form_type=='AnnualLeaveOuting'" ref="annualLeaveOuting" v-model="draft.json_data"></AnnualLeaveOuting>
                            <AnnualLeaveCancel v-if="draft.form_type=='AnnualLeaveCancel'" ref="annualLeaveCancel" v-model="draft.json_data"></AnnualLeaveCancel>

                            <div class="editor-box" v-if="draft.form_type=='HTML'">
                                <CarrotEditor etype="document" v-model="draft.content" class="draftbox"></CarrotEditor>
                            </div>
                            <div v-if="draft.form_type==''" class="alert-box">양식을 선택해주세요.</div>




                          <div class="mt-45 txt-red">※ 결재완료 후 6개월이 경과된 문서의 첨부파일은 자동으로 삭제됩니다. </div>
                            <table class="table-row ">
                                <tbody>
                                    <tr>
                                      <th width="150">파일첨부<br>(파일별 최대 10M)</th>
                                        <td>

                                            <div v-if="draft.idx && draft.file1_name">
                                                <a :href="draft.file1_url" class="btn-view" target="_blank">{{ draft.file1_name }}</a>
                                                <label class="ml-10"><input type="checkbox" v-model="draft.dchk1"> 삭제</label>
                                            </div>
                                            <div v-else>
                                                <CarrotFileUploader maxsize=10 ref="file1"></CarrotFileUploader>
                                            </div>

                                            <div v-if="draft.idx && draft.file2_name">
                                                <a :href="draft.file2_url" class="btn-view" target="_blank">{{ draft.file2_name }}</a>
                                                <label class="ml-10"><input type="checkbox" v-model="draft.dchk2"> 삭제</label>
                                            </div>
                                            <div v-else>
                                                <CarrotFileUploader maxsize=10 ref="file2" class="mt-5"></CarrotFileUploader>
                                            </div>

                                            <div v-if="draft.idx && draft.file3_name">
                                                <a :href="draft.file3_url" class="btn-view" target="_blank">{{ draft.file3_name }}</a>
                                                <label class="ml-10"><input type="checkbox" v-model="draft.dchk3"> 삭제</label>
                                            </div>
                                            <div v-else>
                                                <CarrotFileUploader maxsize=10 ref="file3" class="mt-5"></CarrotFileUploader>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <button class="btn-default float-left mt-30" @click="draft.draftCancel">취소</button>
                            <button class="btn-default float-right mt-30" @click="draft.save">등록</button>
                            <div class="clear"></div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, ref } from 'vue'
import ChooseFormPopup from '@/components/popup/payment/ChooseFormPopup.vue'
import LoadPopup from '@/components/popup/payment/LoadPopup.vue'
import ApprovalLinePopup from '@/components/popup/payment/ApprovalLinePopup.vue'
import ApprovalLineLoadPopup from '@/components/popup/payment/ApprovalLineLoadPopup.vue'
import CarrotEditor from '@/components/common/CarrotEditor.vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import { useRoute, useRouter } from 'vue-router'

import AmountPaid from '@/components/drafting/AmountPaid.vue'
import ExtraPay from '@/components/drafting/ExtraPay.vue'
import OvertimePay from '@/components/drafting/OvertimePay.vue'
import AnnualLeave from '@/components/drafting/AnnualLeave.vue'
import AnnualLeaveExtra from '@/components/drafting/AnnualLeaveExtra.vue'
import WorkAtHome from '@/components/drafting/WorkAtHome.vue'
import ExternalBook from '@/components/drafting/ExternalBook.vue'


import AnnualLeaveNew from '@/components/drafting/AnnualLeaveNew.vue'
import AnnualLeaveOuting from '@/components/drafting/AnnualLeaveOuting.vue'
import AnnualLeaveCancel from "@/components/drafting/AnnualLeaveCancel";

export default {
    components: {
      AnnualLeaveCancel,
        ChooseFormPopup, LoadPopup, ApprovalLinePopup, ApprovalLineLoadPopup, CarrotEditor, 
        // Form 양식
        AmountPaid, ExtraPay, OvertimePay, AnnualLeave, AnnualLeaveExtra, WorkAtHome, ExternalBook,
        AnnualLeaveNew, AnnualLeaveOuting
    },
    setup() {
        const router = useRouter();
        const toast = useToast()
        const route = useRoute();

        const amountPaid =  ref(null);
        const extraPay =  ref(null);
        const overtimePay =  ref(null);
        const annualLeave =  ref(null)
        const annualLeaveExtra =  ref(null);
        const workAtHome =  ref(null);
        const externalBook =  ref(null);


        const annualLeaveNew =  ref(null);
        const annualLeaveCancel= ref(null);
        const annualLeaveOuting =  ref(null);
        
        const file1 = ref(null);
        const file3 = ref(null);
        const file2 = ref(null);
        const draft = reactive({
            // Popup 노출 여부
            isShow: false,
            showForm: false,
            showLoadPopup: false,
            showApprovalLine: false,
            showApprovalLineLoad: false,

            // 양식 데이터
            docinfo : {ename:"", kname:"", deptname:"", no:"", createdate:""},
            idx_modify : 0,
            is_private : false,
            form_type : "",
            form_kind: "",
            form_name: "",
            title : "",
            content: "",
            json_data: "",
            file1_name : "",
            file1_url : "",
            file2_name : "",
            file2_url : "",
            file3_name : "",
            file3_url : "",

            line_staff : [],
            cc_staff : [],

            dchk1 : false, dchk2 : false, dchk3 : false, 

            draftCancel: () => {
                
                router.push({ 
                    name:"documents-AllDocumentList"
                });

            },

            setForm : (form) => {
                draft.form_kind = form.kind;
                draft.form_name = form.form;
                draft.showForm=false;
                draft.form_type = form.form_type;
                if(draft.form_name=='인사기안 신청서') {
                    draft.is_private = true;
                }
                if(form.form_type == "HTML") {
                    let params = {
                        fname : form.file,
                        tgdate : new Date(),
                    };
                    draft.json_data = "";
                    axios.get('/rest/documents/getDocumentForm', { params : params }).then((res) => {
                        if( res.data.err == 0 ){
                            draft.content = res.data.html;
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }

            },



            setStaff : (info) => {
                draft.showApprovalLine = false;
                draft.cc_staff = info.cc_staff;
                draft.line_staff = [];
                for(let i=info.line_staff.length-1;i>=0;i--) {
                    if(info.line_staff[i].is_admin==false) {
                        draft.line_staff.push(info.line_staff[i]);
                    }
                }
                for(let i=info.line_staff.length-1;i>=0;i--) {
                    if(info.line_staff[i].is_admin==true) {
                        info.line_staff[i].position = "관리자";
                        draft.line_staff.push(info.line_staff[i]);
                        break;
                    }
                }
            },

            setInfo: (info) => {

                draft.line_staff = info.line_staff;
                for(let i=0;i<draft.line_staff.length;i++) {
                    draft.line_staff[i]["idx"] = draft.line_staff[i]["idx_staff"];
                    if(draft.line_staff[i]["is_admin"]=="Y") {
                        draft.line_staff[i]["is_admin"] = true;
                    } else {
                        draft.line_staff[i]["is_admin"] = false;
                    }
                }

                draft.cc_staff = info.cc_staff;
                draft.form_kind = info.form_kind;
                draft.form_name = info.form_name;
                draft.form_type = info.form_type;
                draft.title = info.title;
                if(draft.form_type=="HTML") {
                    draft.content = info.content;
                } else {
                    draft.content = "";
                    if(info.json!="") {
                        draft.json_data = JSON.parse(info.json);
                    }

                }
            },

            docInit : () => {
                let params = {
                    tgdate : new Date(),
                };
                axios.get('/rest/documents/getDocumentInit', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        draft.docinfo = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            save : () => {
                if( !draft.title ){
                    toast.error("제목을 입력하세요.");
                    return;
                }
                if( !draft.form_name ){
                    toast.error("양식을 선택해주세요.");
                    return;
                }
                if( draft.line_staff.length<1 ){
                    toast.error("결재선을 지정해주세요.");
                    return;
                }

                if(draft.form_type=='AnnualLeave') {
                    let json = annualLeave.value.comp.getJSON();
                    draft.json_data = JSON.stringify(json);
                    draft.content = "";
                }


                //연차신청서 리뉴얼
                if(draft.form_type=='AnnualLeaveNew') {
                  let json = annualLeaveNew.value.comp.getJSON();
                  draft.json_data = JSON.stringify(json);
                  draft.content = "";
                }

                //연차 변경/취소 신청서
                if(draft.form_type=='AnnualLeaveCancel') {
                  let json = annualLeaveCancel.value.comp.getJSON();
                  draft.json_data = JSON.stringify(json);
                  draft.content = "";
                }



                //아웃팅 신청
                if(draft.form_type=='AnnualLeaveOuting') {
                  let json = annualLeaveOuting.value.comp.getJSON();
                  draft.json_data = JSON.stringify(json);
                  draft.content = "";
                }


                if(draft.form_type=='AnnualLeaveExtra') {
                    let json = annualLeaveExtra.value.comp.getJSON();
                    draft.json_data = JSON.stringify(json);
                    draft.content = "";
                }

                if(draft.form_type=='AmountPaid') {
                    
                    if(amountPaid.value.comp.validation()) {
                        let json = amountPaid.value.comp.getJSON();
                        draft.json_data = JSON.stringify(json);
                        draft.content = "";
                    } else {
                        return;
                    }
                }
                if(draft.form_type=='ExtraPay') {
                    let json = extraPay.value.comp.getJSON();
                    draft.json_data = JSON.stringify(json);
                    draft.content = "";
                }
                if(draft.form_type=='ExternalBook') {
                    let json = externalBook.value.comp.getJSON();
                    draft.json_data = JSON.stringify(json);
                    draft.content = "";
                }
                if(draft.form_type=='OvertimePay') {
                    let json = overtimePay.value.comp.getJSON();
                    draft.json_data = JSON.stringify(json);
                    draft.content = "";
                }
                if(draft.form_type=='WorkAtHome') {
                    if(workAtHome.value.comp.validation()) {
                        let json = workAtHome.value.comp.getJSON();
                        draft.json_data = JSON.stringify(json);
                        draft.content = "";
                    } else {
                        return;
                    }

                }

                for(let i=0;i<draft.line_staff.length;i++) {
                  if(draft.line_staff[i]["is_admin"]=="Y" ||draft.line_staff[i]["is_admin"]==true) {
                    draft.line_staff[i]["is_admin"] = true;
                  } else {
                    draft.line_staff[i]["is_admin"] = false;
                  }
                }
                var formData = new FormData();
                formData.append("idx_modify", draft.idx_modify);    // 수정시 idx

                formData.append("category", draft.form_kind);
                formData.append("form", draft.form_name);

                formData.append("is_private", (draft.is_private?"Y":"N"));
                formData.append("form_type", draft.form_type);
                formData.append("title", draft.title);
                formData.append("content", draft.content);
                formData.append("json_data", draft.json_data);
                formData.append("line_staff", JSON.stringify(draft.line_staff));
                formData.append("cc_staff", JSON.stringify(draft.cc_staff));
                
                if(file1.value!==null && file1.value.file.filedata!==null) {
                    formData.append("file1", file1.value.file.filedata);
                }
                formData.append("file1_name", draft.file1_name);
                formData.append("file1_url", draft.file1_url);

                if(file2.value!==null && file2.value.file.filedata!==null) {
                    formData.append("file2", file2.value.file.filedata);
                }
                formData.append("file2_name", draft.file2_name);
                formData.append("file2_url", draft.file2_url);

                if(file3.value!==null && file3.value.file.filedata!==null) {
                    formData.append("file3", file3.value.file.filedata);
                }
                formData.append("file3_name", draft.file3_name);
                formData.append("file3_url", draft.file3_url);

                formData.append("dchk1", draft.dchk1==true?"Y":"N");
                formData.append("dchk2", draft.dchk2==true?"Y":"N");
                formData.append("dchk3", draft.dchk3==true?"Y":"N");

                axios.post('/rest/documents/addDocument', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{
                    if( res.data.err == 0 ){
                        router.push({ 
                            name:"documents-Document-id",
                            params : { id:res.data.idx }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })

            },


            getDocumentInfo : (idx) => {
                let params = {
                    idx : idx,
                    mode: "view"
                };
                axios.get('/rest/documents/getDocumentInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        draft.idx = res.data.info.idx;
                        draft.title = res.data.info.title;
                        draft.form_kind = res.data.info.category;
                        draft.form_name = res.data.info.form;
                        draft.form_type = res.data.info.form_type;
                        draft.is_private = (res.data.info.is_private=="Y"?true:false);
                        draft.content = res.data.info.content;
                        if(draft.form_type!="HTML" && res.data.info.json!="") {
                            draft.json_data = JSON.parse(res.data.info.json);
                            draft.json_data.idx_staff=res.data.info.idx_staff;
                            draft.json_data.position=res.data.info.position;
                        }
                        draft.docinfo.no = res.data.info.code;
                        file1.value.file.filename = res.data.info.file1;
                        file2.value.file.filename = res.data.info.file2;
                        file3.value.file.filename = res.data.info.file3;

                        draft.file1_name = res.data.info.file1;
                        draft.file1_url = res.data.info.file1_url;

                        draft.file2_name = res.data.info.file2;
                        draft.file2_url = res.data.info.file2_url;

                        draft.file3_name = res.data.info.file3;
                        draft.file3_url = res.data.info.file3_url;
                        draft.line_staff = res.data.line_staff;
                        if(draft.line_staff.length>1) {
                            draft.line_staff.splice(0, 1);
                            for(let i=0;i<draft.line_staff.length;i++) {
                                draft.line_staff[i].idx = draft.line_staff[i].idx_staff;
                            }
                        }
                        draft.cc_staff = res.data.cc_staff;
                        for(let i=0;i<draft.cc_staff.length;i++) {
                            draft.cc_staff[i].idx = draft.cc_staff[i].idx_staff;
                        }

                    } else {
                        if(res.data.err==201) {
                            router.push({ 
                                name:"documents-AllDocumentList"
                            });
                            return;
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    }
                });

            },

        });
        onMounted(() => {
            // Mounted
            draft.docInit();
            // 문서 수정용
            if(route.params.id>0) {
                draft.getDocumentInfo(route.params.id);
                draft.idx_modify = route.params.id;
            }

            // 개인경비 제출용
            if(route.query.mode=="expense") {
                console.info(route.query.year);
                console.info(route.query.month);
            }
        });

        return {draft, amountPaid, extraPay, overtimePay, annualLeave, annualLeaveExtra, workAtHome, externalBook, file1, file2, file3, annualLeaveNew, annualLeaveOuting, annualLeaveCancel};
    }
}
</script>

<style lang="scss" scoped>
    .alert-box {
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px solid #ddd;
        margin-top:10px;
    }
    .draftbox {
        width: 100%;
        height: 500px;
    }
</style>