<template>
  <div>
    <table class="table-row mt-10">
      <tbody>
        <tr>
          <th width="150">구분</th>
          <td>연차 취소/수정 신청서</td>
        </tr>
        <tr>
          <th width="150"><span class="txt-red">*</span> 기간</th>
          <td>
            <span class="bold" v-if="comp.info.list.length == 0"> </span>
            <table class="mt-20 leave-border" v-else>
              <tr class="back-grey">
                <td>날짜</td>
                <td
                  v-for="(irow, i) in comp.info.list.slice(0, 14)"
                  :key="i"
                  class="font-12"
                >
                  {{ irow.udateText }}
                </td>
              </tr>
              <tr>
                <td>
                  <div class="mb-5">연차</div>
                  <div class="mb-5">오전</div>
                  <div class="mb-5">오후</div>
                  <div class="mb-5">취소</div>
                </td>
                <td
                  class="txt-center"
                  v-for="(irow, i) in comp.info.list.slice(0, 14)"
                  :key="i"
                >
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="연차" /></label
                  ><br />
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="오전" /></label
                  ><br />
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="오후" /></label
                  ><br />
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="취소" /></label
                  ><br />
                </td>
              </tr>

              <tr class="back-grey" v-if="comp.info.list.length > 15">
                <td>날짜</td>
                <td
                  v-for="(irow, i) in comp.info.list.slice(14)"
                  :key="i"
                  class="font-12"
                >
                  {{ irow.udateText }}
                </td>
              </tr>
              <tr v-if="comp.info.list.length > 15">
                <td>
                  <div class="mb-5">연차</div>
                  <div class="mb-5">오전</div>
                  <div class="mb-5">오후</div>
                  <div class="mb-5">취소</div>
                </td>
                <td
                  class="txt-center"
                  v-for="(irow, i) in comp.info.list.slice(14)"
                  :key="i"
                >
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="연차" /></label
                  ><br />
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="오전" /></label
                  ><br />
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="오후" /></label
                  ><br />
                  <label
                    ><input
                      type="radio"
                      v-model="irow.ctype"
                      value="취소" /></label
                  ><br />
                </td>
              </tr>
            </table>

            <div class="color-1">
              ※ 취소 및 변경이 가능한 연차가 표시됩니다.<br />
              ※ 기안서 최초 작성 시점 보다 취소 및 변경할 휴가가 미래 날짜인
              경우만 표시됩니다.<br />
              ※ 연차 취소할 경우 "취소" 선택<br />
              ※ 연차 변경할 경우 "해당 사항" 선택
            </div>
          </td>
        </tr>

        <tr>
          <th width="150">신청 사유</th>
          <td>
            <textarea
              class="w-100per h-100px"
              style="padding: 10px; line-height: 180%"
              v-model="comp.info.memo"
            ></textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from "vue";
import axios from "@/plugins/axios";

export default {
  name: "AnnualLeaveCancel",
  components: {},
  props: {
    modelValue: {
      type: [Object, String],
      default: null,
    },
  },
  setup(props) {
    const comp = reactive({
      info: {
        ctype: "",
        sdate: "",
        edate: "",
        memo: "",
        count: "00",
        list: [],
      },
      getJSON: () => {
        let data = {
          info: comp.info,
        };
        return data;
      },
      Init: () => {
        let params = {};
        axios
          .get("/rest/personal/getGNBInfo", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              comp.count = res.data.vacation.count;
              comp.info.count = res.data.vacation.count;
            }
          });
      },
      getVacationCancel: () => {
        let params = {};
        axios
          .get("/rest/personal/getVacationCancelList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              comp.info.list = res.data.list;
            }
          });
      },
    });

    watch(
      () => props.modelValue,
      (nVal, oVal) => {
        if (nVal != oVal) {
          comp.info = props.modelValue.info;
        }
      }
    );

    onMounted(() => {
      comp.Init();
      if (props.modelValue != null && props.modelValue != "") {
        comp.info = props.modelValue.info;
      } else {
        //입력시에만
        comp.getVacationCancel();
      }
    });

    return { comp };
  },
};
</script>
<style lang="scss" scoped></style>
