<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-900px">
            <div>
                <p class="title">불러오기</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <tbody>
                        <tr>
                            <th width="180">결재 제목</th>
                            <td>
                                <input type="text" v-model="pop.search.title" class="w-100per">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn-default float-right mt-10" @click="pop.getDocuments()">검색</button>
                <div class="clear"></div>

                <div class="mt-50 mb-20">
                    <table class="tbl-light-list">
                        <colgroup>
                            <col width="60">
                            <col width="125">
                            <col width="*">
                            <col width="180">
                            <col width="100">
                            <col width="90">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>문서번호</th>
                                <th>제목</th>
                                <th>양식</th>
                                <th>등록일</th>
                                <th>선택</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(p, idx) in pop.list" :key="idx">
                                <td>{{p.num}}</td>
                                <td>{{p.code}}</td>
                                <td class="txt-left"><span class="btn-view">{{p.title}}</span></td>
                                <td>{{p.form}}</td>
                                <td>{{p.cdate}}</td>
                                <td>
                                    <button class="btn-default dp-inblock h-30px" @click="pop.setData(p.idx)">선택</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging v-if="pop.total>0" :total="pop.total" :list_per_page="10" v-model="pop.search.page" @change="pop.getDocuments()"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from '@/plugins/axios.js'
import { useToast } from 'vue-toastification';

export default {
    name: 'LoadPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    emits: [ 'close', 'selected' ],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            search: {
                title: "",
                page:1
            },
            list : [],
            info : {
                line_staff : [],
                line_cc : [],
                docinfo : {},
                content: "",
            },
            
            setData : (idx) => {
                let params = {
                    idx : idx
                };
                axios.get('/rest/documents/getDocumentInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info.line_staff = res.data.line_staff;
                        pop.info.line_staff.splice(0, 1);
                        pop.info.cc_staff = res.data.cc_staff;
                        pop.info.form_kind = res.data.info.category;
                        pop.info.form_name = res.data.info.form;
                        pop.info.form_type = res.data.info.form_type;
                        pop.info.title = res.data.info.title;
                        pop.info.content = res.data.info.content;
                        pop.info.json = res.data.info.json;
                        emit('selected', pop.info);
                        emit('close');
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            getDocuments : () => {
                let params = {
                    page : pop.search.page,
                    title: pop.search.title,
                    tgdate : new Date(),
                };
                axios.get('/rest/documents/getMyDocuments', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });
        onMounted(() => {
            // Mounted
            pop.getDocuments();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>