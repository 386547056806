<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-1000px">
            <div>
                <p class="title">결재선 설정 안내</p>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="txt-left pb-10">
                    - 결재선 지정 시 결재양식 관리자를 꼭 체크해 주세요.
                </div>
                <div class="overhidden overscroll maxh-570px">
                    <table class="tbl-light-list">
                        <colgroup>
                            <col width="90">
                            <col width="120">
                            <col width="90">
                            <col width="70">
                            <col width="70">
                            <col width="140">
                            <col width="90">
                            <col width="*">
                            <col width="90">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th colspan="2">양식</th>
                                <th>담당</th>
                                <th>팀장</th>
                                <th>본부장</th>
                                <th>대표이사</th>
                                <th>관리자 지정</th>
                                <th>참 조</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th rowspan="9">경비</th>
                                <td class="border-l txt-left" rowspan="2">지출결의서(회사)</td>
                                <td class="border-l txt-left">10만원 이하</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left">10만원 초과</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">지출결의서(개인경비)</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">지출결의서(법인)</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">지출결의서(수당)</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">구매요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">상품권 사용신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">결재 취소/환불 요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">강사급여 추가 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="3">교재</th>
                                <td class="border-l txt-left" colspan="2">연구교재 구입신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">외부교재 구입신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">제본교재 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="3">인센티브</th>
                                <td class="border-l txt-left" colspan="2">추가 수당 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l">회계팀</td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">전화영어 인센티브 신청서(B2B)</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l">회계팀</td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">야근수당 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l">회계팀</td>
                            </tr>
                            <tr>
                                <th rowspan="5">개발</th>
                                <td class="border-l txt-left" colspan="2">개발요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">캐럿솔루션즈 본부장(애슐리님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">홍보자료 제작요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">캐럿솔루션즈 본부장(애슐리님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">교재개발요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">캐럿솔루션즈 본부장(애슐리님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">개인정보 요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">캐럿솔루션즈 본부장(애슐리님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">고객사 등록 요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">캐럿솔루션즈 본부장(애슐리님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="2">서비스지원</th>
                                <td class="border-l txt-left" colspan="2">Complain 처리 보고서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">캐럿솔루션즈 본부장(애슐리님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">PP처리보고서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">캐럿솔루션즈 본부장(애슐리님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="5">교육</th>
                                <td class="border-l txt-left" colspan="2">교육신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">사외위탁교육 서약서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">사외교육 보고서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">필독도서 보고서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">생활멘토 활동일지</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="6">휴가</th>
                                <td class="border-l txt-left" colspan="2">Outing 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l">대상자 팀장</td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">연차신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">팀장은 본부장 결재</td>
                                <td class="border-l">본부장은 대표이사 결재</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">휴직신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">팀장은 본부장 결재</td>
                                <td class="border-l">본부장은 대표이사 결재</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">멘토링 불참 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>c
                                <td class="border-l">팀장은 본부장 결재</td>
                                <td class="border-l">본부장은 대표이사 결재</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">재택관리</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="2">출장</th>
                                <td class="border-l txt-left" colspan="2">출장신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">출장보고서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="3">복리후생</th>
                                <td class="border-l txt-left" colspan="2">대출신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">동호회 승인 요청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">차량 사용 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <th rowspan="4">인사</th>
                                <td class="border-l txt-left" colspan="2">One Page Proposal</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">아르바이트 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l"></td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">인재채용 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">경영기획본부장 (Louis님)</td>
                                <td class="border-l"></td>
                            </tr>
                            <tr>
                                <td class="txt-left" colspan="2">인사기안 신청서</td>
                                <td class="border-l">담당</td>
                                <td class="border-l">팀장</td>
                                <td class="border-l">본부장</td>
                                <td class="border-l">대표이사</td>
                                <td class="border-l">인사팀 팀장</td>
                                <td class="border-l"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'ApprovalLineInfoPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>