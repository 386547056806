<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-530px">
            <div>
                <p class="title">양식선택</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="dp-table w-100per">
                    <!-- 첫번째 뎁스메뉴 -->
                    <div class="dp-tablecell w-50per pr-15">
                        <div class="borderDDD-box">
                            <div class="h-450px overhidden overscroll">
                                <!-- 양식 선택 전 : 샘플 -->
                                <ul class="list btn-list" :class="{'has-active':pop.selectedCart>=0}">
                                    <li class="h-50px" v-for="(itm, idx) in pop.category" :key="idx"><button class="btn-sel1" :class="{'active': pop.selectedCart==idx}" @click="pop.setCart(idx)">{{itm}}</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- //첫번째 뎁스메뉴 -->
                    <!-- 두번째 뎁스메뉴 -->
                    <div class="dp-tablecell w-50per pl-15">
                        <div class="borderDDD-box">
                            <div class="h-450px overhidden overscroll">
                                <!-- 양식 선택 전 -->
                                <div class="text-before pt-15 pl-20" v-show="pop.selectedCart<0">양식을 먼저 선택해주세요.</div>
                                <!-- 양식 선택 후 -->
                                <ul class="list btn-list" :class="{'has-active':pop.selectedSubCart>=0}">
                                    <template v-for="(itm, idx) in pop.subCartList.list" :key="idx">
                                        <li v-if="itm.formname!='지출결의서(개인경비)' && itm.formname!='지출결의서(법인)' && itm.formname!='지출결의서(수당)'" class="h-50px"><button class="btn-sel2" :class="{'active': pop.selectedSubCart==idx}" @click="pop.setSubCart(idx)">{{itm.formname}}</button></li>
                                    </template>
                                    <!-- <li class="h-50px"><button class="btn-sel2">구매요청서</button></li>
                                    <li class="h-50px"><button class="btn-sel2 active">상품권 사용신청서</button></li>
                                    <li class="h-50px"><button class="btn-sel2">결제 취소/환불 요청서</button></li>
                                    <li class="h-50px"><button class="btn-sel2">강사급여 추가 신청서</button></li> -->
                                    <!-- 메뉴가 9개 이상일때 마지막 li에 noline-b추가, 그 이하일때는 추가없음 -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- //두번째 뎁스메뉴 -->
                </div>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left" @click="pop.onClose()">취소</button>
                <button class="btn-default float-right" @click="pop.setUpdate()">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'ChooseFormPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    emits: [ 'onFormSelect', 'onClose' ],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            currentCart: "",
            category: [
                '경비', '교재', '인센티브', '개발', '서비스지원', '교육', '휴가', '출장', '복리후생', '인사'
            ],
            forms: [],
            subCartList: [],
            subcart: [
                {
                    cart: '경비',
                    list: ['지출결의서', '구매요청서', '상품권 사용신청서', '결재 취소/환불 요청서', '강사급여 추가 신청서']
                },
                {
                    cart: '교육',
                    list: ['교육신청서', '사외위탁교육 서약서', '사외교육 보고서', '필독도서 보고서', '생활멘토 활동일지']
                },
                {
                    cart: '교재',
                    list: ['연구교재 구입신청서', '외부교재 구입신청서', '제본교재 신청서']
                },
            ],
            selectedCart: -1,
            selectedSubCart: -1,
            docInit : () => {
                let params = {
                    tgdate : new Date(),
                };
                axios.get('/rest/documents/getForms', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.forms = res.data.forms;
                        pop.category = [];
                        for(let i=0;i<res.data.forms.length;i++) {
                            pop.category.push(res.data.forms[i].category);
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            setCart: (idx) => {
                pop.selectedCart = idx;
                pop.selectedSubCart = -1;
                pop.subCartList = pop.forms[idx];
            },
            setSubCart(idx) {
                pop.selectedSubCart = idx;
                console.info(pop.subCartList.list[idx]);
            },
            setUpdate() {

              const selectedSubCart = pop.subCartList.list[pop.selectedSubCart];

              const emitOnFormSelect = () => {
                emit('onFormSelect', {
                  kind: pop.category[pop.selectedCart],
                  form: selectedSubCart.formname,
                  form_type: selectedSubCart.form,
                  file: selectedSubCart.file,
                });
              };

              if (selectedSubCart.form === 'AnnualLeaveCancel') {
                axios
                    .get('/rest/personal/getVacationCancelList')
                    .then((res) => {
                      if (res.data.err === 0) {
                        if (res.data.cnt === 0) {
                          alert(
                              '현재 취소 가능한 휴가가 없습니다\n피플팀에 문의해주세요.'
                          );
                        } else {
                          emitOnFormSelect();
                        }
                      }
                    });
              } else {
                emitOnFormSelect();
              }
            },
            onClose: () => {
                emit('onClose');
            }
        });

        onMounted(() => {
            // Mounted
            pop.docInit();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
    // .btn-list {
    //     li {
    //         &:last-child {
    //             // border-bottom: 0 none !important;
    //         }
    //     }
    // }
</style>