<template>
    <div>
        <SelectClassPopup v-if="comp.showClassSelector" @selected="comp.setClass" @close="comp.showClassSelector=false"></SelectClassPopup>
        <table class="table-row mt-10">
            <colgroup>
                <col width="150">
                <col width="*">
                <col width="150">
                <col width="*">
            </colgroup>
            <tbody>
                <tr>
                    <th><span class="txt-red">*</span> 총 금액</th>
                    <td>
                        {{comp.intToCost(comp.info.amount)}}
                    </td>
                    <th><span class="txt-red">*</span> 지출일</th>
                    <td>
                        <label><input type="radio" name="cday" value="15" v-model="comp.info.cday"><span class="ml-5 mr-20"> 15일</span></label>
                        <label><input type="radio" name="cday" value="25" v-model="comp.info.cday"><span class="ml-5 mr-20"> 25일</span></label>
                    </td>
                </tr>
                <tr>
                    <th><span class="txt-red">*</span> 법인구분</th>
                    <td colspan="3">
                        <label><input type="radio" name="corporation" v-model="comp.info.corporation" value="캐럿글로벌"><span class="ml-5 mr-20"> 캐럿글로벌</span></label>
                      <label><input type="radio" name="corporation" v-model="comp.info.corporation" value="캐럿솔루션즈"><span class="ml-5 mr-20"> 캐럿솔루션즈</span></label>
                      <label><input type="radio" name="corporation" v-model="comp.info.corporation" value="캐럿에듀"><span class="ml-5 mr-20"> 캐럿에듀</span></label>
                      <label><input type="radio" name="corporation" v-model="comp.info.corporation" value="밝음"><span class="ml-5 mr-20"> 밝음</span></label>
                        <!--<label><input type="radio" name="corporation" v-model="comp.info.corporation" value="캐럿에듀플러스"><span class="ml-5 mr-20"> 캐럿에듀플러스</span></label>
                        <label><input type="radio" name="corporation" v-model="comp.info.corporation" value="밝음"><span class="ml-5 mr-20"> 밝음</span></label>-->
                    </td>
                </tr>
                <tr>
                    <th><span class="txt-red">*</span> 지출증빙</th>
                    <td colspan="3">
                        <label><input type="radio" name="proof" v-model="comp.info.proof" value="강사급여"><span class="ml-5 mr-20"> 강사급여</span></label>
                        <label><input type="radio" name="proof" v-model="comp.info.proof" value="교재비"><span class="ml-5 mr-20"> 교재비</span></label>
                        <label><input type="radio" name="proof" v-model="comp.info.proof" value="기타경비"><span class="ml-5 mr-20"> 기타경비</span></label>
                    </td>
                </tr>
                <tr>
                    <th>특이사항</th>
                    <td colspan="3">
                        <textarea class="w-100per h-100px" maxlength="500" v-model="comp.info.memo" ></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="block-title mt-50 mb-20">강사지급금액 상세</div>
        <div v-for="(itm, idx) in comp.items" :key="idx">
            <table class="table-row mb-10">
                <colgroup>
                    <col width="150">
                    <col width="*">
                    <col width="150">
                    <col width="*">
                </colgroup>
                <tbody>
                    <tr>
                        <th>ITM 클래스</th>
                        <td colspan="3">
                            <div class="float-left w-85per">{{itm.classname}}</div>
                            <button class="btn-default float-right" @click="comp.showClass(idx)">클래스 선택</button>
                        </td>
                    </tr>
                    <tr>
                        <th>매니저</th>
                        <td>
                            <input type="text" class="w-100per" v-model="itm.hq_name">
                        </td>
                        <th>강사이름</th>
                        <td>
                            <input type="text" class="w-100per" v-model="itm.tutor_name">
                        </td>
                    </tr>
                    <tr>
                        <th><span class="txt-red">*</span> 강사지급금액</th>
                        <td>
                            <CarrotCostInput v-model="itm.cost" @update:modelValue="comp.costChange"></CarrotCostInput>
                        </td>
                        <th>출석누락일</th>
                        <td>
                            <input type="number" class="w-80px" v-model="itm.missday">
                        </td>
                    </tr>
                    <tr>
                        <th><span class="txt-red">*</span> 은행/계좌번호</th>
                        <td>
                            <CarrotBankSelect v-model="itm.bank"></CarrotBankSelect>
                            <input type="text" class="w-215px ml-10" v-model="itm.account_number">
                        </td>
                        <th><span class="txt-red">*</span> 예금주</th>
                        <td>
                            <input type="text" class="w-100per" v-model="itm.account_name">
                        </td>
                    </tr>
                    <tr>
                        <th>비고(사유기재)</th>
                        <td colspan="3">
                            <textarea class="w-100per h-100px" v-model="itm.memo"></textarea>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="dp-table w-100per">
            <button class="btn-default float-left" @click="comp.addItem">+ 강사지급금액 추가</button>
            <button class="btn-default float-right" @click="comp.removeItem">- 강사지급금액 삭제</button>
        </div>

        <div class="color-1 mt-50 mb-20">※ 증빙서류 미첨부 및 작성 양식이 정확히 기재되어있지 않을 경우 지급이 지연될 수 있습니다.</div>
    </div>
</template>
<script>
import { onMounted, reactive, watch, computed } from 'vue'
import CarrotBankSelect from '@/components/input/CarrotBankSelect.vue'
import CarrotCostInput from '@/components/input/CarrotCostInput.vue'
import SelectClassPopup from '@/components/popup/payment/SelectClassPopup.vue'
import { useToast } from 'vue-toastification';

export default {
    name: 'AmountPaid',
    components: {
        CarrotBankSelect, CarrotCostInput, SelectClassPopup
    },
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const toast = useToast()
        const comp = reactive({
            showClassSelector: false,
            selectedClassSelector: 0,
            sdate:"",
            info: {
                amount: computed(() => {
                    let n =0;
                    for(let i=0;i<comp.items.length;i++) {
                        n = n + parseInt(comp.items[i].cost);
                    }
                    return n;
                }),
                cday: 0,
                corporation: "",
                proof: "",
                memo: ""
            },
            items: [{
                classname:"",
                idx_hq: 0,
                hq_name: "",
                idx_tutor: 0,
                tutor_name: "",
                cost: 0,
                day: 0,
                bank: "",
                bank_etc:"",
                account_number : "",
                account_name : "",
                memo:"",
            }],
            validation : () => {
                let vali = true;
                if(comp.info.cday==0) {
                    vali = false;
                    toast.error("지출일을 선택해주세요.");
                }
                if(comp.info.corporation=='') {
                    vali = false;
                    toast.error("법인구분을 선택해주세요.");
                }
                if(comp.info.proof=='') {
                    vali = false;
                    toast.error("지출증빙을 선택해주세요.");
                }
                for(let i=0;i<comp.items.length;i++) {
                    if(comp.items[i].cost=="") {
                        vali = false;
                        toast.error("강사지급금액을 입력해주세요.");
                        break;
                    }
                    if(comp.items[i].bank=="") {
                        vali = false;
                        toast.error("은행을 선택해주세요.");
                        break;
                    }
                    if(comp.items[i].account_number=="") {
                        vali = false;
                        toast.error("계좌번호을 입력해주세요.");
                        break;
                    }
                    if(comp.items[i].account_name=="") {
                        vali = false;
                        toast.error("예금주를 입력해주세요.");
                        break;
                    }
                }
                return vali;
            },
            getJSON: () => {
                let data = {
                    list : comp.items,
                    info : comp.info,
                }
                return data;
            },
            removeItem: () => {
                comp.items.pop();
            },
            showClass: (idx) => {
                comp.selectedClassSelector = idx;
                comp.showClassSelector = true;
            },
            addItem: () => {
                comp.items.push({
                    classname:"",
                    idx_hq: 0,
                    hq_name: "",
                    idx_tutor: 0,
                    tutor_name: "",
                    cost: 0,
                    missday: 0,
                    bank: "",
                    account_number : "",
                    account_name : "",
                    memo: ""
                });
            },
            setClass: (sltClass) => {
                comp.items[comp.selectedClassSelector].classname = sltClass.classname;
                comp.items[comp.selectedClassSelector].idx_hq = sltClass.hq_idx;
                comp.items[comp.selectedClassSelector].hq_name = sltClass.hq_name;
                comp.items[comp.selectedClassSelector].tutor_name = sltClass.tutor_name;
            },
            costChange: () => {
                console.info("AA");
            },
            update: () => {
                emit('update:modelValue', comp.dateToString());
            },
            dateToString() {
                return [comp.sdate.getFullYear(),((comp.sdate.getMonth() + 1)>9 ? '' : '0') + (comp.sdate.getMonth() + 1),(comp.sdate.getDate()>9 ? '' : '0') + comp.sdate.getDate()].join('-');
            },
            intToCost(price) {
                let cost = price.toString().replace(/,/g, "");
                cost = parseFloat(cost);
                if(isNaN(cost)) {
                    cost = 0;
                }
                return cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }

        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.sdate = nVal;
            }
        });

        onMounted(() => {
            // Mounted
            console.info(props.modelValue);
            if(props.modelValue!=null && props.modelValue!="") {
                comp.info.cday = props.modelValue.info.cday;
                comp.info.corporation = props.modelValue.info.corporation;
                comp.info.proof = props.modelValue.info.proof;
                comp.info.memo = props.modelValue.info.memo;
                comp.items = props.modelValue.list;
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
.dept-select {
    display: inline-block;
    select {
        width: 200px;
        margin-right: 10px;
    }
}
</style>