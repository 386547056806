<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-900px">
            <div>
                <p class="title">결재선 불러오기</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="40">
                        <col width="60">
                        <col width="100">
                        <col width="*">
                        <col width="240">
                        <col width="100">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label><input type="checkbox" v-model="pop.checkAll" @change="pop.doCheckAll"></label>
                            </th>
                            <th>No</th>
                            <th>결재선명</th>
                            <th>결재자</th>
                            <th>참조자</th>
                            <th>불러오기</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(line, idx) in pop.list" :key="line.idx">
                            <td>
                                <label><input type="checkbox" v-model="line.check"></label>
                            </td>
                            <td>{{pop.list.length - idx}}</td>
                            <td>{{line.title}}</td>
                            <td>{{line.line_staff}}</td>
                            <td>{{line.cc_staff}}</td>
                            <td>
                                <button class="btn-default dp-inblock h-30px" @click="pop.getInfo(line.idx)">선택</button>
                            </td>
                        </tr>
                        <tr v-if="pop.list.length==0">
                            <td colspan="6">
                                저장된 결재선이 없습니다.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button class="btn-default float-left" @click="pop.delItems">선택 삭제</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'ApprovalLineLoadPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    emits: ['close', 'selected'],
    setup(props, {emit}) {
        const toast = useToast()
        const pop = reactive({
            list: [{check:false}],
            info: {cc:[], line:[]},
            checkAll: false,
            onClose: () => {
                emit('close');
            },
            delItems : () => {
                let lines = [];
                for(let i=0; i<pop.list.length;i++) {
                    if(pop.list[i].check==true) {
                        lines.push(pop.list[i].idx);
                    }
                }
                if(lines.length==0) {
                    toast.error("삭제하실 결재선을 선택해주세요.");
                    return;
                }
                let params = {
                    lines : lines
                };
                axios.post('/rest/documents/delLineList', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("결재선이 삭제되었습니다.");
                        pop.getList();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            doCheckAll : () => {
                let check = false;
                if(pop.checkAll==true) {
                    check = true;
                }
                for(let i=0; i<pop.list.length;i++) {
                    pop.list[i].check = check;
                }
            },
            getList : () => {
                let params = {
                    tgdate : new Date(),
                };
                axios.get('/rest/documents/getLineList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            getInfo : (idx) => {
                let params = {
                    idx : idx
                };
                axios.get('/rest/documents/getLineInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data.info;
                        emit('selected', pop.info);
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });

            }


        });
        onMounted(() => {
            // Mounted
            pop.getList();
        });


        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>