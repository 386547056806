<template>
    <div>
        <table class="table-row mt-10">
            <tbody>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 구분</th>
                    <td>
                        <label><input type="radio" v-model="comp.info.ctype" value="연차"><span class="ml-5 mr-20"> 연차</span></label>
                        <label><input type="radio" v-model="comp.info.ctype" value="오전 반차"><span class="ml-5 mr-20"> 오전 반차</span></label>
                        <label><input type="radio" v-model="comp.info.ctype" value="오후 반차"><span class="ml-5 mr-20"> 오후 반차</span></label>
                    </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 기간</th>
                    <td>
                        <CarrotDatePicker v-model="comp.info.sdate"></CarrotDatePicker> ~ <CarrotDatePicker v-model="comp.info.edate"></CarrotDatePicker>
                        <div class="color-1">
                            ※ 법정공휴일 또는 주말은 자동 제외됩니다.<br>
                            ※ 불특정 공휴일(대체공휴일 등)은 자동 제외되지 않으니 유의하시기 바랍니다.
                        </div>
                    </td>
                </tr>
                <tr>
                  <th width="150"> 잔여연차개수</th>
                  <td>
                    {{comp.info.count  }}개
                    <div class="color-1">
                      ※ 잔여 연차를 초과하여 연차 사용시 (마이너스 연차) 해당 월 급여에서 공제 처리됩니다.
                    </div>
                  </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 연차로 인한 <br>업무 조정 내용</th>
                    <td>
                        <textarea class="w-100per h-100px" style="padding:10px; line-height:180%;" v-model="comp.info.memo"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import axios from "@/plugins/axios";

export default {
    name: 'AnnualLeave',
    components: {
        CarrotDatePicker
    },
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        }
    },
    setup(props) {
        const comp = reactive({
            info: {
                ctype : "",
                sdate : "",
                edate : "",
                memo : "",
                count:"00",
            },
            getJSON: () => {
                let data = {
                    info : comp.info,
                }
                return data;
            },
            Init : () => {
              let params = {
              };
              axios.get("/rest/personal/getGNBInfo", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  comp.count = res.data.vacation.count;
                  comp.info.count = res.data.vacation.count;
                }
              });
            },
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.info = props.modelValue.info;
            }
        });

        onMounted(() => {
          comp.Init();
            if(props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
</style>