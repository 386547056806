<template>
    <select class="bank-select" v-model="comp.bank" @change="comp.update">
        <option value="">은행선택</option>
        <option :value="itm" v-for="(itm, idx) in comp.banklist" :key="idx">{{itm}}</option>
    </select>
</template>
<script>
import { onMounted, reactive } from 'vue'

export default {
    name: 'CarrotBankSelect',
    components: {
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {

        const comp = reactive({
            bank : "",
            banklist : [
                "신한은행", "국민은행", "농협은행", "수협은행", "우리은행", "한국씨티은행", "케이뱅크은행", "제주은행", "기업은행", "산업은행", "신협은행", "하나은행", "카카오뱅크"
                , "부산은행", "경남은행", "SC은행", "우체국", "대구은행", "기타(직접입력)"
            ],
            update: () => {
                emit('update:modelValue', comp.bank);
            }
        });

        onMounted(() => {
            // Mounted
            comp.bank = props.modelValue;
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
.bank-select {
    width: 150px;
}
</style>