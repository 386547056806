<template>
  <div>
    <table class="table-row mt-10">
      <tbody>
      <tr>
        <th width="150"> 구분</th>
        <td> 아웃팅신청서 </td>
      </tr>
      <tr>
        <th width="150"><span class="txt-red">*</span> 기간</th>
        <td>
          <CarrotDatePicker @change="comp.getAvailableVacation()" v-model="comp.info.sdate"></CarrotDatePicker>
          ~
          <CarrotDatePicker @change="comp.getAvailableVacation()" v-model="comp.info.edate"></CarrotDatePicker>
          <span  class=" bold" v-if="comp.info.list.length==0">
            ← 아웃팅 기간을 선택해주세요.
          </span>
          <table class="mt-20 mb-10 leave-border" v-else>

            <tr class="back-grey">
              <td>날짜</td>
              <td v-for="(irow, i)  in comp.info.list.slice(0, 14)" :key="i" class="font-12">
                {{ irow.udateText }}
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-5"> 연차 </div>
                <div class="mb-5"> 오전 </div>
                <div class="mb-5"> 오후 </div>
              </td>
              <td class="txt-center" v-for="(irow, i) in comp.info.list.slice(0, 14)" :key="i">
                <label><input type="radio" v-model="irow.ctype" value="연차" ></label><br>
                <label><input type="radio" v-model="irow.ctype" value="오전" ></label><br>
                <label><input type="radio" v-model="irow.ctype" value="오후" ></label><br>
              </td>
            </tr>

            <tr class="back-grey" v-if="comp.info.list.length > 15">
              <td>날짜</td>
              <td v-for="(irow, i) in comp.info.list.slice(14)" :key="i" class="font-12">
                {{ irow.udateText }}
              </td>
            </tr>
            <tr v-if="comp.info.list.length > 15">
              <td>
                <div class="mb-5"> 연차 </div>
                <div class="mb-5"> 오전 </div>
                <div class="mb-5"> 오후 </div>
              </td>
              <td  class="txt-center" v-for="(irow, i) in comp.info.list.slice(14)" :key="i">
                <label><input type="radio" v-model="irow.ctype" value="연차" ></label><br>
                <label><input type="radio" v-model="irow.ctype" value="오전" ></label><br>
                <label><input type="radio" v-model="irow.ctype" value="오후" ></label><br>
              </td>
            </tr>

          </table>

          <div class="color-1">
            ※ 법정공휴일 또는 주말은 자동 제외되어 표기됩니다.
          </div>
        </td>
      </tr>
      <tr>
        <th width="150"> 잔여연차개수</th>
        <td>
          {{ comp.info.count }}개
          <div class="color-1">
            ※ 잔여 연차를 초과하여 연차 사용시 (마이너스 연차) 해당 월 급여에서 공제 처리됩니다.
          </div>
        </td>
      </tr>
      <tr>
        <th width="150"><span class="txt-red">*</span> 연차로 인한 <br>업무 조정 내용</th>
        <td>
          <textarea class="w-100per h-100px" style="padding:10px; line-height:180%;"
                    v-model="comp.info.memo"></textarea>
        </td>
      </tr>
      <tr>
        <th width="150">신청 사유</th>
        <td>
          <textarea class="w-100per h-100px" style="padding:10px; line-height:180%;" v-model="comp.info.request_memo"></textarea>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import { onMounted, reactive, watch } from 'vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import axios from "@/plugins/axios";

export default {
    name: 'AnnualLeaveOuting',
    components: {
        CarrotDatePicker
    },
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        }
    },
    setup(props) {
        const comp = reactive({
            info: {
                ctype : "",
                sdate : "",
                edate : "",
                memo : "",
                request_memo:"",
                count:"00",
                list : [],
            },
            getJSON: () => {
                let data = {
                    info : comp.info,
                }
                return data;
            },
            Init : () => {
              let params = {
              };
              axios.get("/rest/personal/getGNBInfo", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  comp.count = res.data.vacation.count;
                  comp.info.count = res.data.vacation.count;
                }
              });
            },
            getAvailableVacation: () => {
              if(comp.info.sdate>comp.info.edate &&  comp.info.edate!=''){
                alert("시작일을 종료일보다 과거로 설정해주세요");
              }
              let params = {
                sdate : comp.info.sdate,
                edate : comp.info.edate
              };
              axios.get("/rest/personal/getAvailableVacationList", { params : params }).then((res) => {
                if( res.data.err == 0 ){
                  comp.info.list = res.data.list;
                }
              });
            }
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.info = props.modelValue.info;
            }
        });

        onMounted(() => {
          comp.Init();
            if(props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
</style>