<template>

    <div class="carrot-layer-bg">
        <ApprovalLineLoadPopup v-if="pop.showApprovalLineLoad" @close="pop.showApprovalLineLoad=false" @selected="pop.loadApprovalLine"></ApprovalLineLoadPopup>
       <ApprovalLineInfoPopup v-if="pop.showApprovalLineInfoPopup"   @close="pop.showApprovalLineInfoPopup=false"></ApprovalLineInfoPopup>

      <div class="carrot-layer w-900px">
            <div class="dp-table w-100per">
                <p class="title float-left">결재선 선택</p>
                <div class="layer-btn float-left ml-15">
                    <button class="btn-default font-14"  @click="pop.showApprovalLineInfoPopup=true">결재선 설정 안내</button>
                </div>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <div class="dp-table w-100per">
                    <!-- 본부,팀 목록 + 선택목록 -->
                    <div class="float-left w-495">

                      <!-- 본부,팀 목록 new-->
                      <div class="dp-inblock w-230px h-610px borderDDD-box ver-top treelist"  >
                        <div tag="ul"   v-for="t in pop.teams_new" :key="t.idx" >
                          <div>
                            <button class="btn-menubox ml-5 mt-10" :class="{'active':pop.idx_sector==t.idx}" @click="pop.setSector(t)">
                              {{t.deptname}}</button>
                          </div>
                          <ul :class="{'group-hide':pop.idx_sector!=t.idx}">
                            <div v-for="c in t.children" :key="c.idx"  >
                              <button class="btn-menubox ml-20 mt-10" :class="{'active':c.idx==pop.idx_office}" @click="pop.setOffice(c)">{{c.deptname}}
                              </button>
                                <div  :class="{'group-hide':pop.idx_office!=c.idx}" v-for="b in c.children" :key="b.idx"  >
                                  <button class="btn-menubox ml-35 mt-10" :class="{'active':b.idx==pop.idx_team}" @click="pop.setTeam(b)">{{b.deptname}}
                                  </button>
                                </div>
                            </div>
                          </ul>
                        </div>
                      </div>
                        <!-- 선택목록 -->
                        <div class="dp-inblock w-250px h-610px ml-15 ver-top po-relative">
                            <div class="hidden-borderDDD"></div>
                            <div class="searchChk-list">
                                <div class="search-box">
                                    <input type="text" v-model="pop.find_name" placeholder="이름을 검색하세요.">
                                </div>
                              <!-- 부문선택 -->
                              <ul class="check-list" v-if="pop.selected=='sector'">
                                <li class="on"  v-show="pop.in_staff.length>0">
                                  <label><input type="checkbox" v-model="pop.checkAll" @change="pop.doCheckAll"><span class="ml-5 mr-20">{{pop.sector_name}}({{pop.in_staff.length}})</span></label>
                                  <ul v-for="sector in pop.selected_sector" :key="sector.idx">
                                    <p style="display: none"> {{cnt=0}} </p>
                                    <p v-for="staff in pop.in_staff" :key="staff.idx">
                                    <span v-if="(staff.idx_office==sector.idx) || (staff.idx_team==sector.idx && sector.is_team=='Y')" style="display: none">
                                      {{cnt++}}
                                    </span>
                                    </p>
                                    <li class="on">
                                      <label>
                                        <input type="checkbox" @change="pop.doCheckOffice(sector.idx)">
                                        <span class="ml-5 mr-20">{{sector.deptname}}({{cnt}})
                                        </span>
                                      </label>
                                      <ul>
                                        <template v-for="staff in pop.in_staff" :key="staff.idx" >
                                          <li v-if="(staff.idx_office==sector.idx) || (staff.idx_team==sector.idx && sector.is_team=='Y') && (pop.find_name.trim()=='' || staff.kname.indexOf(pop.find_name)>=0 || staff.ename.toLowerCase().indexOf(pop.find_name.toLowerCase())>=0)">
                                            <label><input type="checkbox" v-model="staff.checked" :value="staff.idx"> <span class="ml-5 mr-20">
                                              {{staff.kname}} ({{staff.ename}})</span></label>
                                          </li>
                                        </template>
                                      </ul>
                                    </li>
                                  </ul>
                                  <ul >
                                    <p style="display: none"> {{cnt=0}}</p>
                                    <p v-for="staff in pop.in_staff" :key="staff.idx">
                                    <span v-if="staff.idx_team==0" style="display: none">
                                      {{cnt++}}
                                    </span>
                                    </p>
                                    <li class="on">
                                      <label>
                                        <input type="checkbox"  @change="pop.doCheckOffice(0)">
                                        <span class="ml-5 mr-20">무소속({{cnt}})
                                        </span>
                                      </label>
                                      <ul>
                                        <template v-for="staff in pop.in_staff" :key="staff.idx" >
                                          <li v-if="staff.idx_team==0 && (pop.find_name.trim()=='' || staff.kname.indexOf(pop.find_name)>=0 || staff.ename.toLowerCase().indexOf(pop.find_name.toLowerCase())>=0)">
                                            <label><input type="checkbox" v-model="staff.checked" :value="staff.idx"> <span class="ml-5 mr-20">
                                                {{staff.kname}} ({{staff.ename}})</span></label>
                                          </li>
                                        </template>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                              <!-- 부문선택 -->

                              <!-- 본부선택 -->
                              <ul class="check-list" v-if="pop.selected=='office'">
                                <li class="on"  v-show="pop.in_staff.length>0">
                                  <label><input type="checkbox" v-model="pop.checkAll" @change="pop.doCheckAll"><span class="ml-5 mr-20">{{pop.office_name}}({{pop.in_staff.length}})</span></label>

                                  <!-- 부문-팀 case -->
                                  <ul v-if="pop.is_team=='Y'">
                                    <p style="display: none"> {{cnt=0}}</p>
                                    <p v-for="staff in pop.in_staff" :key="staff.idx">
                                    <span  style="display: none">
                                      {{cnt++}}
                                    </span>
                                    </p>

                                    <li class="on">
                                      <label>
                                        <input type="checkbox" @change="pop.doCheckTeam(pop.idx_office)">
                                        <span class="ml-5 mr-20">{{pop.office_name}}({{cnt}})
                                        </span>
                                      </label>
                                      <ul>
                                        <template v-for="staff in pop.in_staff" :key="staff.idx" >
                                          <li>
                                            <label><input type="checkbox" v-model="staff.checked" :value="staff.idx"> <span class="ml-5 mr-20">
                                            {{staff.kname}} ({{staff.ename}})</span></label>
                                          </li>
                                        </template>
                                      </ul>
                                    </li>

                                  </ul>

                                  <!-- 부문-본부 Case-->
                                  <ul v-for="office in pop.selected_office" :key="office.idx">
                                    <p style="display: none"> {{cnt=0}}</p>
                                    <p v-for="staff in pop.in_staff" :key="staff.idx">
                                    <span v-if="staff.idx_team==office.idx" style="display: none">
                                      {{cnt++}}
                                    </span>
                                    </p>


                                    <li class="on">
                                      <label>
                                        <input type="checkbox" @change="pop.doCheckTeam(office.idx)">
                                        <span class="ml-5 mr-20">{{office.deptname}}({{cnt}})
                                        </span>
                                      </label>
                                      <ul>
                                        <template v-for="staff in pop.in_staff" :key="staff.idx" >
                                          <li v-if="staff.idx_team==office.idx && (pop.find_name.trim()=='' || staff.kname.indexOf(pop.find_name)>=0 || staff.ename.toLowerCase().indexOf(pop.find_name.toLowerCase())>=0)">
                                            <label><input type="checkbox" v-model="staff.checked" :value="staff.idx"> <span class="ml-5 mr-20">
                                            {{staff.kname}} ({{staff.ename}})</span></label>
                                          </li>
                                        </template>
                                      </ul>
                                    </li>

                                  </ul>
                                  <ul>
                                    <p style="display: none"> {{cnt=0}}</p>
                                    <p v-for="staff in pop.in_staff" :key="staff.idx">
                                    <span v-if="staff.idx_team==0" style="display: none">
                                      {{cnt++}}
                                    </span>
                                    </p>
                                    <li class="on">
                                      <label>
                                        <input type="checkbox"  @change="pop.doCheckTeam(0)">
                                        <span class="ml-5 mr-20">무소속({{cnt}})
                                        </span>
                                      </label>
                                      <ul>
                                        <template v-for="staff in pop.in_staff" :key="staff.idx" >
                                          <li v-if="staff.idx_team==0 && (pop.find_name.trim()=='' || staff.kname.indexOf(pop.find_name)>=0 || staff.ename.toLowerCase().indexOf(pop.find_name.toLowerCase())>=0)">
                                            <label><input type="checkbox" v-model="staff.checked" :value="staff.idx"> <span class="ml-5 mr-20">
                                             {{staff.kname}} ({{staff.ename}})</span></label>
                                          </li>
                                        </template>
                                      </ul>
                                    </li>
                                  </ul>


                                </li>
                              </ul>
                              <!-- 본부선택 -->

                              <!-- 팀선택 -->
                              <ul class="check-list" v-if="pop.selected=='team'">
                                <li class="on"  v-show="pop.in_staff.length>0">
                                  <ul>
                                    <li class="on">
                                      <label><input type="checkbox" v-model="pop.checkAll" @change="pop.doCheckAll"><span class="ml-5 mr-20">
                                        {{pop.team_name}}({{pop.in_staff.length}})</span></label>
                                      <ul>
                                        <template v-for="staff in pop.in_staff" :key="staff.idx">
                                          <li v-if="pop.find_name.trim()=='' || staff.kname.indexOf(pop.find_name)>=0 || staff.ename.toLowerCase().indexOf(pop.find_name.toLowerCase())>=0">
                                            <label><input type="checkbox" v-model="staff.checked" :value="staff.idx"> <span class="ml-5 mr-20">
                                             {{staff.kname}} ({{staff.ename}})</span></label>
                                          </li>
                                        </template>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                              <!-- 팀선택 -->
                            </div>
                        </div>
                        <!-- //선택목록 -->
                    </div>
                    <!-- //본부,팀 목록 + 선택목록 -->

                    <!-- 결재선 + 참조 -->
                    <div class="float-right w-360px h-610px ver-top">
                        <!-- 결재선 -->
                        <div class="dp-table w-100per">
                            <div class="dp-tablecell w-110px txt-center">
                                <button class="btn-default font-14 dp-inblock plr-10 w-80px" @click="pop.setLine()">결재선 <span class="ico-arrow"></span></button>
                            </div>
                            <div class="dp-tablecell w-250px">
                                <div class="dp-table w-100per">
                                    <span class="block-stitle float-left mt-5">결재선</span>
                                    <button class="btn-default float-right font-14 plr-10" @click="pop.showApprovalLineLoad=true">불러오기</button>
                                </div>
                                <div class="borderDDD-box mt-10 h-200px overhidden overscroll">
                                    <div class="selList-box">
                                        <div class="selbox" v-for="(staff, idx) in pop.line_staff" :key="staff.idx">
                                            <label class="label-sel"><input type="radio" name="admin" v-model="pop.idx_admin" :value="staff.idx"><span class="ml-5 mr-20"> {{staff.kname}} ({{staff.ename}})</span></label>
                                            <button class="btn-del" @click="pop.delLineStaff(idx)">X</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-default mt-10">관리자를 지정해주세요.</div>
                            </div>
                        </div>
                        <!-- //결재선 -->
                        <!-- 참조 -->
                        <div class="dp-table w-100per mt-25">
                            <div class="dp-tablecell w-110px txt-center">
                                <button class="btn-default font-14 dp-inblock plr-10 w-80px" @click="pop.setCC()">참조 <span class="ico-arrow"></span></button>
                            </div>
                            <div class="dp-tablecell w-250px">
                                <div class="dp-table w-100per">
                                    <span class="block-stitle float-left mt-5">참조자</span>
                                </div>
                                <div class="borderDDD-box mt-10 h-200px overhidden overscroll">
                                    <div class="selList-box">
                                        <div class="selbox" v-for="(staff, idx) in pop.cc_staff" :key="staff.idx">
                                            <button class="btn-sel">{{staff.kname}} ({{staff.ename}})</button>
                                            <button class="btn-del" @click="pop.delCCStaff(idx)">X</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="dp-table w-100per mt-20">
                                    <span class="block-stitle float-left w-65px mt-5">결재선 명</span>
                                    <input type="text" class="float-left w-130px h-32px borderR-0" maxlength="10" v-model="pop.line_title">
                                    <button class="btn-default float-left font-14 w-55px ml-m1" @click="pop.saveLine()">저장</button>
                                </div>
                            </div>
                        </div>
                        <!-- //참조 -->
                    </div>
                    <!-- //결재선 + 참조 -->
                </div>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table txt-center">
                <button class="btn-default dp-inblock" @click="pop.onClose()">취소</button>
                <button class="btn-default dp-inblock ml-20" @click="pop.sendStaff()">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import ApprovalLineLoadPopup from '@/components/popup/payment/ApprovalLineLoadPopup.vue'
import ApprovalLineInfoPopup from '@/components/popup/payment/ApprovalLineInfoPopup.vue'
import { useStore } from 'vuex'

export default {
    name: 'ApprovalLinePopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    components: {
        ApprovalLineLoadPopup,
      ApprovalLineInfoPopup
    },

    setup(props, {emit}) {
        const toast = useToast()
        const store = useStore()

        const pop = reactive({
            teams : [],
            staff : [],
            in_staff : [],
            selected : "",
            selected_office : [],
            selected_sector:[],
            line_staff : [],
            cc_staff: [],
            line_title: "",
            idx_team: 0,
            idx_sector : 0,
            idx_office : 0,
            idx_admin: 0,
            active_office : 0,
            active_sector : 0,
            find_name:"",
            sector_name: "",
            office_name: "",
            team_name: "",
            showApprovalLineLoad: false,
            checkAll: false,
            is_team:'N',
            doCheckAll : () => {
                let val = false;
                if(pop.checkAll == true) {
                    val = true;
                }
                for(let i=0;i<pop.in_staff.length;i++) {
                    pop.in_staff[i].checked = val;
                }
            },
            doCheckTeam : (team) => {

              for(let i=0;i<pop.in_staff.length;i++) {
                if(pop.in_staff[i].idx_team == team){

                  if(pop.in_staff[i].checked==true)
                    pop.in_staff[i].checked=false;
                  else
                    pop.in_staff[i].checked = true;

                }
              }
            },
            doCheckOffice : (office) => {

              for(let i=0;i<pop.in_staff.length;i++) {
                if(pop.in_staff[i].idx_office == office){

                  if(pop.in_staff[i].checked==true)
                    pop.in_staff[i].checked=false;
                  else
                    pop.in_staff[i].checked = true;

                }
              }
            },
            //결재선 관련
            setLine: () => {
                let err_msg = 0;
                for(let i=0;i<pop.in_staff.length;i++) {
                    if(pop.in_staff[i].checked == true) {
                        if(pop.line_staff.length<5) {
                            pop.addLineStaff(pop.in_staff[i]);
                        } else {
                            err_msg = "결재선은 5인이상 등록하실 수 없습니다.";
                            break;
                        }
                    }
                }
                pop.line_staff.sort((a, b) => {
                    let check = a.idx_position - b.idx_position;
                    if(check==0) {
                        if(store.state.idx_team>0) {
                            if(b.idx_team == store.state.idx_team && a.idx_team != store.state.idx_team) {
                                check = -1;
                            }
                        } else {
                            if(b.idx_office == store.state.idx_office && a.idx_office != store.state.idx_office) {
                                check = -1;
                            }
                        }
                    }
                    return check;
                })
                if(err_msg!='') {
                    toast.error(err_msg);
                }
            },

            addLineStaff : (staff) => {
                let check = true;
                for(let i=0;i<pop.line_staff.length;i++) {
                    if(pop.line_staff[i].idx == staff.idx) {
                        check = false;
                    }
                }
                if(check) {
                    pop.line_staff.push(staff);
                }
            },

            delLineStaff: (idx) => {
                pop.line_staff.splice(idx, 1);
            },

            // 참조자 관련
            delCCStaff: (idx) => {
                pop.cc_staff.splice(idx, 1);
            },

            addCCStaff : (staff) => {
                let check = true;
                for(let i=0;i<pop.cc_staff.length;i++) {
                    if(pop.cc_staff[i].idx == staff.idx) {
                        check = false;
                    }
                }
                if(check) {
                    pop.cc_staff.push(staff);
                }
            },
            goMail : () => {
              window.open("https://carrotians.net/member/login", "_blank");
            },

            setCC: () => {
                let err_msg = 0;
                for(let i=0;i<pop.in_staff.length;i++) {
                    if(pop.in_staff[i].checked == true) {
                        pop.addCCStaff(pop.in_staff[i]);
                    }
                }
                if(err_msg!='') {
                    toast.error(err_msg);
                }
            },

            loadApprovalLine : (info) => {
                pop.line_staff = [];
                pop.cc_staff = [];
                pop.line_staff = info.line;
                pop.cc_staff = info.cc;
                pop.showApprovalLineLoad=false;
                pop.showApprovalLineInfoPopup=false;
                pop.getAdmin();
            },
            
            saveLine: () => {
                if(pop.line_title.trim().length==0) {
                    toast.error("결재선명을 입력해주세요.");
                    return;
                }
                if(pop.line_staff.length==0) {
                    toast.error("결재자를 선택해주세요..");
                    return;
                }

                pop.setAdmin();

                let params = {
                    title : pop.line_title,
                    line : pop.line_staff,
                    cc: pop.cc_staff
                };

                axios.post("/rest/documents/saveLine", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("결재선이 저장되었습니다.");
                    } else {
                        console.log(res.data.err_msg);
                    }
                });

            },

            setOffice : (office) => {
                pop.checkAll = false;
                pop.active_office = 0;
                pop.idx_office = office.idx;
                pop.idx_team = 0;
                pop.in_staff = [];
                pop.selected="office";
                pop.office_name = office.deptname;
                pop.team_name = "";
                pop.selected_office=office.children;
                pop.is_team=office.is_team;

                for(let i=0;i<pop.staff.length;i++) {
                  if(pop.staff[i].idx_office == office.idx && (pop.staff[i].idx_office==0 || pop.staff[i].idx_office==office.idx)) {

                    pop.in_staff.push(pop.staff[i]);
                  }
                  if(pop.staff[i].idx_team == office.idx  && office.is_team=='Y') {

                    pop.in_staff.push(pop.staff[i]);
                  }

                }
                for(let i=0;i<pop.in_staff.length;i++) {
                  pop.in_staff[i].checked = false;
                }


            },
            setSector : (sector) => {
              pop.checkAll = false;
              pop.active_sector = 0;
              pop.idx_sector = sector.idx;
              pop.idx_office = 0;
              pop.idx_team = 0;
              pop.selected="sector";
              pop.selected_sector=sector.children;

              pop.in_staff = [];

              pop.sector_name = sector.deptname;
              pop.office_name = "";
              pop.team_name = "";
              for(let i=0;i<pop.staff.length;i++) {
                if(pop.staff[i].idx_sector == sector.idx && (pop.staff[i].idx_sector==0 || pop.staff[i].idx_sector==sector.idx)) {
                  pop.in_staff.push(pop.staff[i]);
                }
              }
              for(let i=0;i<pop.in_staff.length;i++) {
                pop.in_staff[i].checked = false;
              }
            },
          setTeam: (team_info) => {
            pop.checkAll = false;
            pop.in_staff = [];
            pop.idx_team = team_info.idx;
            pop.team_name = team_info.deptname;
            pop.selected="team";
            for(let i=0; i<pop.staff.length; i++) {
              if(pop.staff[i].idx_office == team_info.idx_office && (pop.staff[i].idx_team==team_info.idx)) {
                pop.in_staff.push(pop.staff[i]);
              }
            }
            for(let i=0;i<pop.in_staff.length;i++) {
              pop.in_staff[i].checked = false;
            }
          },
            teamSearch : () => {
                let params = {
                    tgdate : new Date(),
                };
                axios.get('/rest/documents/getTeamTree', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.teams = res.data.tree;
                        pop.teams_new = res.data.p_tree;
                        pop.staff = res.data.staff;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },



            getAdmin : () => {
                for(let i=0;i<pop.line_staff.length;i++) {
                    if(pop.line_staff[i].is_admin == true) {
                        pop.idx_admin = pop.line_staff[i].idx;
                    }
                }
            },

            setAdmin : () => {
                for(let i=0;i<pop.line_staff.length;i++) {
                    pop.line_staff[i].is_admin = false;
                    if(pop.line_staff[i].idx == pop.idx_admin) {
                        pop.line_staff[i].is_admin = true;
                    }
                }
            },

            sendStaff : () => {
                if(pop.line_staff.length==0) {
                    toast.error("결재자를 선택해주세요.");
                    return;
                }

               //등록 시점에 관리자 선택되었는지.
                var checkAdmin=false;
                for(let i=0;i<pop.line_staff.length;i++) {
                  if(pop.line_staff[i].idx == pop.idx_admin) {
                    checkAdmin= true;
                  }
                }

                if(!checkAdmin){
                  toast.error("관리자를 선택해주세요.");
                  return;
                }

                pop.setAdmin();

                emit('selected', {line_staff : pop.line_staff, cc_staff: pop.cc_staff});
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.teamSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
    .group-hide {
        display: none;
    }
    .scrollbox {
        overflow: hidden;
        overflow-y: auto;
    }
    .treelist {
      overflow: hidden;
      overflow-y: auto;
    }
    .check-list {
        overflow: hidden;
        overflow-y: auto;
        max-height: 573px;
    }
</style>