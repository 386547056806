<template>
    <div>
        <table class="table-row mt-10">
            <tbody>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 구분</th>
                    <td>
                        <label><input type="radio" v-model="comp.info.ctype" value="연차"><span class="ml-5 mr-20"> 연차</span></label>
                        <label><input type="radio" v-model="comp.info.ctype" value="오전 반차"><span class="ml-5 mr-20"> 오전 반차</span></label>
                        <label><input type="radio" v-model="comp.info.ctype" value="오후 반차"><span class="ml-5 mr-20"> 오후 반차</span></label>
                    </td>
                </tr>
                <tr>
                    <th width="150">선택</th>
                    <td>
                        <select class="w-200px" v-model="comp.info.reason">
                            <option value="">선택</option>
                            <option value="연차">연차</option>
                            <option value="루키연차">루키연차</option>
                            <option value="경조휴가">경조휴가</option>
                            <option value="산전후휴가">산전후휴가</option>
                            <option value="배우자출산">배우자출산</option>
                            <option value="외근">외근</option>
                            <option value="출장">출장</option>
                            <option value="교육">교육</option>
                            <option value="워크샵">워크샵</option>
                            <option value="병역훈련">병역훈련</option>
                            <option value="백신휴가">백신휴가</option>
                            <option value="휴일근무">휴일근무</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 기간</th>
                    <td>
                        <CarrotDatePicker v-model="comp.info.sdate"></CarrotDatePicker> ~ <CarrotDatePicker v-model="comp.info.edate"></CarrotDatePicker>
                        <div class="color-1">
                            ※ 법정공휴일 또는 주말은 자동 제외됩니다.<br>
                            ※ 불특정 공휴일(대체공휴일 등)은 자동 제외되지 않으니 유의하시기 바랍니다.
                        </div>
                    </td>
                </tr>
                <tr>
                    <th width="150"><span class="txt-red">*</span> 연차로 인한 <br>업무 조정 내용</th>
                    <td>
                        <textarea class="w-100per h-100px" style="padding:10px; line-height:180%;" v-model="comp.info.memo"></textarea>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { onMounted, reactive, watch } from 'vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'

export default {
    name: 'AnnualLeave',
    components: {
        CarrotDatePicker
    },
    props: {
        modelValue: {
            type: [Object, String],
            default: null,
        }
    },
    setup(props) {
        const comp = reactive({
            info: {
                ctype : "",
                reason : "",
                sdate : "",
                edate : "",
                memo : "",
            },
            getJSON: () => {
                let data = {
                    info : comp.info,
                }
                return data;
            },
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.info = props.modelValue.info;
            }
        });

        onMounted(() => {
            if(props.modelValue!=null && props.modelValue!="") {
                comp.info = props.modelValue.info;
            }
        });

        return {comp};
    }
}
</script>
<style lang="scss" scoped>
</style>